
body { font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 400; color: #888; line-height: 30px; text-align: center; }

strong { font-weight: 600; }

/*a { color: #856dc0; border-bottom: 1px dashed #856dc0; text-decoration: none; transition: all .3s; }*/
/*a:hover, a:focus { color: #856dc0; border: 0; text-decoration: none; }*/

h1, h2 { margin-top: 10px; font-size: 38px; font-weight: 300; color: #555; line-height: 50px; font-style: italic; }
h3 { font-size: 22px; font-weight: 300; color: #555; line-height: 30px; font-style: italic; }

img { max-width: 100%; }

.medium-paragraph { font-size: 17px; line-height: 32px; }

::-moz-selection { background: #7762ac; color: #fff; text-shadow: none; }
::selection { background: #7762ac; color: #fff; text-shadow: none; }


/***** General style, all sections *****/

.section-container { margin: 0 auto; padding-bottom: 80px; }

.section-container-gray-bg { background: #f4f4f4; }

.section-container-grayer { background: #dedede; }

.section-container-image-bg,
.section-container-image-bg h2,
.section-container-image-bg h3 { color: #fff; }

.section-container-image-bg .divider-1 span { border-color: #fff; }

.section-description { margin-top: 60px; padding-bottom: 10px; }

.section-description p { margin-top: 20px; padding: 0 120px; }
.section-container-image-bg .section-description p { opacity: 0.8; }

.section-bottom-button { padding-top: 60px; }


/***** Divider *****/

.divider-1 span { display: inline-block; width: 200px; border-bottom: 1px dotted #aaa; }


/***** Top content *****/

.inner-bg { padding: 210px 0; }

.top-content .text { color: #fff; }
.top-content .text h1 { margin-top: 25px; color: #fff; }
.top-content .description { margin: 30px 0 10px 0; }
.top-content .description p { opacity: 0.8; }


/***** Section 1 *****/

.section-1-box { padding-top: 30px; text-align: left; }

.section-1-box .section-1-box-icon {
    width: 80px; height: 80px; margin: 0 auto;

    background: #7762ac; /* Old browsers */
    background: -moz-linear-gradient(top,  #856dc0 0%, #7762ac 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#856dc0), color-stop(100%,#7762ac)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #856dc0 0%,#7762ac 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#856dc0', endColorstr='#7762ac',GradientType=0 ); /* IE6-9 */

    font-size: 40px; color: #fff; line-height: 80px; text-align: center;
    -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%;
    -o-transition: all .6s; -moz-transition: all .6s; -webkit-transition: all .6s; -ms-transition: all .6s; transition: all .6s;
}

.section-1-box h3 { margin-top: 0; padding: 0 0 10px 0; }


/***** Section 2 *****/

.section-2-box { margin-top: 30px; text-align: left; }
.section-2-box h3 { margin-top: 0; }
.section-2-box-text .medium-paragraph { margin-top: 20px; margin-bottom: 20px; }


/***** Section 3 *****/

.section-3-box { margin-top: 30px; text-align: left; }

.section-3-box .section-3-box-icon {
    width: 60px; height: 60px; margin: 0 auto;

    background: #7762ac; /* Old browsers */
    background: -moz-linear-gradient(top,  #856dc0 0%, #7762ac 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#856dc0), color-stop(100%,#7762ac)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #856dc0 0%,#7762ac 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #856dc0 0%,#7762ac 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#856dc0', endColorstr='#7762ac',GradientType=0 ); /* IE6-9 */

    font-size: 28px; color: #fff; line-height: 60px; text-align: center;
    -moz-border-radius: 50%; -webkit-border-radius: 50%; border-radius: 50%;
}

.section-3-box h3 { margin-top: 0; padding: 0 0 10px 0; }


/***** Footer *****/

.footer-top { padding: 60px 0; background: #333; text-align: left; color: #aaa; }
.footer-top h3 { padding-bottom: 10px; color: #ffffff; }

.footer-about img.logo-footer { max-width: 74px; margin-top: 0; margin-bottom: 18px; }
.footer-about p a { color: #aaa; border-bottom: 1px dashed #666; }
.footer-about p a:hover, .footer-about p a:focus { color: #fff; border-color: #aaa; }

.footer-contact p { word-wrap: break-word; }
.footer-contact i { padding-right: 10px; font-size: 18px; color: #666; }
.footer-contact p a { color: #aaa; border-bottom: 1px dashed #666; }
.footer-contact p a:hover, .footer-contact p a:focus { color: #fff; border-color: #aaa; }

.footer-social a { display: inline-block; margin-right: 20px; margin-bottom: 8px; color: #777; border: 0; }
.footer-social a:hover, .footer-social a:focus { color: #aaa; border: 0; }
.footer-social i { font-size: 24px; vertical-align: middle; }

.footer-bottom { padding: 15px 0; background: #444; text-align: left; color: #aaa; }

.footer-copyright p { margin: 0; padding: 0.5rem 0; }
.footer-copyright a { color: #fff; border: 0; }
.footer-copyright a:hover, .footer-copyright a:focus { color: #aaa; border: 0; }

/* footer navbar */
/*.navbar { padding: 0; background: #444; backface-visibility: hidden; }*/

/*.navbar-dark .navbar-nav { font-size: 15px; color: #fff; font-weight: 400; }*/
/*.navbar-dark .navbar-nav .nav-link { color: #fff; border: 0; }*/
/*.navbar-dark .navbar-nav .nav-link:hover { color: #aaa; }*/
/*.navbar-dark .navbar-nav .nav-link:focus { color: #aaa; outline: 0; }*/

/*.navbar-expand-md .navbar-nav .nav-link { padding-left: 1rem; padding-right: 1rem; }*/





