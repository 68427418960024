html {
  scroll-behavior: smooth;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}


.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

body {
  height: 100%;
}

body {
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #888;
  line-height: 30px;
  text-align: center;
}

.navbar {
  background-color: #000;
}

.carousel-item {
  height: 95vh;
  min-height: 50vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*when screen width size becomes 800px or less then the carousel pictures height sets to auto for better visualisation*/
@media (max-width:800px) {
  .carousel-item{
  height: auto;
}
  #FirstHeading {
    font-size: 20px;
  }

  #SecondHeading {
    font-size: 10px;
    text-shadow: 0 3px 8px #000;
  }
}

.carouselItem {
  text-align: left;
  margin-left: 0;
  color: #ffffff;
  /*font-size: 30px;*/
}

#FirstHeading {
  font-size: 60px;
  font-weight: 700;
  color: white;
  text-shadow: 0 1px 8px #000;
}

#SecondHeading {
  position: center;
  font-size: 20px;
  text-shadow: 0 3px 8px #000;
}

h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: "Comic Sans MS", cursive, sans-serif
}

h2.container{
  font-family: "Arial Black", Gadget, sans-serif
}

.noborder {
  background: none;
}
.specLabel {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  white-space: nowrap;
  padding-right: 10px;
}
.special {
  background-color: #f2f4f7;
}
.phoneTitle {
  text-align: center;
  font-weight: bold;
}
img:hover:not(#specImage) {
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);

  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

#mainContent {
  min-height: 100vh;
}

#contact {
  padding: 5% 10% 5% 10%;
}

/*social share*/
.social-container {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  text-align: center;
}

h2.social-container{
  font-family: "Comic Sans MS", cursive, sans-serif;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  /*font-size: 12px;*/
  /*line-height: 15px;*/
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}

/************************************************/
.customer-logos {
  background-color: #111;
}

/* Slider */
.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider
{
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/*style.css mdb*/
.btn:focus, .btn:active, button:focus, button:active {
  outline: none !important;
  box-shadow: none !important;
}

#image-gallery .modal-footer{
  display: block;
}

.thumb{
  margin-top: 15px;
  margin-bottom: 15px;
}

 /*Google translate styles*/
.translated-ltr{margin-top:-40px;}
.translated-ltr{margin-top:-40px;}
.goog-te-banner-frame {display: none;margin-top:-20px;}

.goog-logo-link {
  display:none !important;
}

.goog-te-gadget{
  color: transparent !important;
  margin-top: 8px;
}

/*.goog-te-combo{*/
/*  margin-top: 18px;*/
/*}*/

.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}